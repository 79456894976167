<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path clip-rule="evenodd"
          d="M32.3784 40C33.2268 40 33.6898 39.0099 33.1457 38.3588L24.7771 28.3433C24.3767 27.8641 23.6399 27.865 23.2407 28.3453L14.9168 38.3608C14.3752 39.0124 14.8386 40 15.6858 40L32.3784 40ZM24.0128 31.3276L29.1703 37.5L18.883 37.5L24.0128 31.3276ZM8.00008 25.25L10.5001 25.25L10.5001 22.75L8.00008 22.75L8.00008 25.25ZM12.8749 25.25L15.3749 25.25L15.3749 22.75L12.8749 22.75L12.8749 25.25ZM20.3124 25.25L17.8124 25.25L17.8124 22.75L20.3124 22.75L20.3124 25.25ZM22.6875 25.25L25.1875 25.25L25.1875 22.75L22.6875 22.75L22.6875 25.25ZM30.125 25.25L27.625 25.25L27.625 22.75L30.125 22.75L30.125 25.25ZM32.5625 25.25L35.0625 25.25L35.0625 22.75L32.5625 22.75L32.5625 25.25ZM40 25.25L37.5 25.25L37.5 22.75L40 22.75L40 25.25ZM24.8218 19.6552C24.4226 20.1355 23.6858 20.1364 23.2853 19.6572L14.9167 9.6417C14.3727 8.99062 14.8356 8.00051 15.6841 8.00051L32.3766 8.00051C33.2238 8.00051 33.6872 8.98811 33.1457 9.63968L24.8218 19.6552Z"
          fill="inherit"
          fill-rule="evenodd"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-reflect-h-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 48 48'
    }
  }
}
</script>
